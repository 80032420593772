import { get } from 'lodash';

export const getAppSettings = (state) => state.appSettings;

export const getAppSettingsValue = ({ state, key, fallback = undefined }) =>
  get(getAppSettings(state), key, fallback);

export const getAppSettingsNumber = (state, key, fallback = 1) =>
  getAppSettingsValue({ state, key: `style.numbers.${key}`, fallback });

export const getAppSettingsFont = (state, key, fallback = undefined) =>
  getAppSettingsValue({ state, key: `style.fonts.${key}`, fallback });
