import { IWixAPI } from '@wix/yoshi-flow-editor';
import { isExperimentEnabled } from '@wix/communities-blog-client-common';
import {
  EXPERIMENT_USE_FEED_PAGE_ADAPTER_IN_EDITOR,
  EXPERIMENT_USE_FEED_PAGE_ADAPTER_IN_VIEWER,
} from '@wix/communities-blog-experiments';
import { AppState } from '../types';

type ViewMode = IWixAPI['window']['viewMode'];
type ViewModeLowercase = Lowercase<ViewMode>;

export const isFeedAdapterExperimentEnabled = (
  state: AppState,
  wixCodeWindowViewMode: ViewMode | ViewModeLowercase,
) => {
  const viewMode = wixCodeWindowViewMode.toLowerCase() as ViewModeLowercase;
  if (viewMode === 'site') {
    return isExperimentEnabled(
      state,
      EXPERIMENT_USE_FEED_PAGE_ADAPTER_IN_VIEWER,
    );
  }

  return isExperimentEnabled(state, EXPERIMENT_USE_FEED_PAGE_ADAPTER_IN_EDITOR);
};
